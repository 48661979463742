import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles, Typography } from "@material-ui/core"

import SEO from "../components/seo"
import BookList from "../components/book-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

// import CategoryBg from "../../static/images/mainBg.jpg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  PublishersBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
  description: {
    marginTop: 20,
    marginBottom: 20,
  },
}))

const PublisherTemplate = ({ data, pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const { name, image, pLang, strapiId } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", function () {
      windowGlobal.history.pushState(
        pLang,
        `publisher`,
        `/${i18n.language}/publishers/${strapiId}`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          `publisher`,
          `/${i18n.language}/publishers/${strapiId}`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/publishers/${strapiId}`
      }
    })
  }, [i18n, pLang, strapiId])

  const nameForLang =
    i18n.language === "ar"
      ? data.strapiPublishers.name_ar
      : i18n.language === "tr"
      ? data.strapiPublishers.name_tr
      : i18n.language === "en" && data.strapiPublishers.name

  const breadcrumbs = [
    {
      text: t("publishers.PageName"),
      link: `${i18n.language}/publishers`,
    },
    {
      text: nameForLang,
    },
  ]

  const books = []
  for (let book of data.allStrapiBooks.nodes) {
    let objCopy = Object.assign({}, book)
    books.push(objCopy)
  }

  const descriptionForLang =
    i18n.language === "ar"
      ? data.strapiPublishers.description_ar
      : i18n.language === "tr"
      ? data.strapiPublishers.description_tr
      : i18n.language === "en" && data.strapiPublishers.description

  return (
    <>
      <SEO title={name} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={nameForLang} image={image} border={true} />
      <Container className={`${classes.fullWidth} ${classes.PublishersBg}`}>
        <Container className={classes.description}>
          <Typography
            component="div"
            className={classes.authorDescription}
            dangerouslySetInnerHTML={{ __html: descriptionForLang }}
          />
          {/* <Typography>
            {i18n.language === "ar"
              ? data.strapiPublishers.description_ar
              : i18n.language === "tr"
              ? data.strapiPublishers.description_tr
              : i18n.language === "en" && data.strapiPublishers.description}
          </Typography> */}
        </Container>
        <BookList books={books} />
      </Container>
    </>
  )
}
export default PublisherTemplate

export const query = graphql`
  query PublisherTemplate($slug: String!, $id: String!) {
    allStrapiBooks(filter: {publisher: {slug: {eq: $slug}}, book_items: {elemMatch: {type: {eq: "paper"}}}}) {
      nodes {
        id
        slug
        ISBN
        title
        title_ar
        title_tr
        rating
        quantity
        strapiId
        image {
          publicURL        }
        book_items {
          id
          price
          title
          type
        }
      }
    }
    strapiPublishers(id: { eq: $id }) {
      id
      slug
      name
      name_tr
      name_ar
      country
      strapiId
      description
      description_ar
      description_tr
      image {
        publicURL
      }
    }
    allStrapiPublishers {
      nodes {
        id
        name
        name_tr
        name_ar
        slug
        country
        strapiId
        image {
          publicURL
        }
      }
    }
  }
`
// childImageSharp {
//   fixed(width: 169, height: 244) {
//     ...GatsbyImageSharpFixed
//   }
// }
